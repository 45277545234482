#members {
    h2 {
        font-size: 20px;
    }
    p {
        font-size: 14px;
    }
    img {
        width: 22%;
        border: 1px solid orangered;
        border-radius: 7px;
        padding: 10px 5px;
    }
}
