#all {
    min-height: 100vh;
    /*background: linear-gradient(230deg, #0093D2 50%, #FF851B 50%) repeat;*/
    background: linear-gradient(230deg, #007db3 50%, #ce6b14 50%) repeat;
    margin: 0;
    font-family: 'Helvetica Neue', Verdana, Tahoma, Arial, Helvetica, sans-serif;
    text-align: center;
}
#center {
    display: inline-block;
    box-sizing: border-box;
    max-width: 100%;
    max-width: 100vw;

    padding: 10px 3vw 15px 3vw;

    margin: 100px auto 0 auto;
    margin-top: calc(50vh - 65px);

    /*border: 1px solid black;*/
    /*border-style: dashed none dashed none;*/

    box-shadow: 19px 19px 28px -14px rgba(15, 15, 15, 0.53);

    overflow: hidden;
    text-overflow: ellipsis;

    animation: 1s fade-in ease-in-out;

    text-align: center;
    background: rgba(255, 255, 255, 1);
}
span#err-404 {
    margin-bottom: 50px;

    white-space: nowrap;

    color: #ff4136;
    font-size: 35px;
    font-weight: bold;
}
/*span#url {
    color: #444;
    font-weight: bold;
  }*/

@keyframes fade-in {
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}
