.main {
    min-height: 623px;
    background-image: url('https://i.imgur.com/35hHEGK.png');
    background-size: 100%;
    height: calc(1100 / 750 * 100vw);
    width: 100%;
}

.grab-make-money {
    width: 69vw;
    height: 1.6rem;
    margin: 0 auto !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    font-size: 12px;
    top: 40vw;
    padding-top: 79vw;
    .grab-make-left {
        width: 70%;
        // float: left;
        text-align: left;
        display: inline-block;
    }
    .grab-make-left .h3 {
        overflow: hidden;
        height: 6vw;
        line-height: 6vw;
        color: #e75370;
        text-align: center;
        font-weight: 400;
        font-size: 2.9vw;
    }
    .grab-make-right {
        width: 15vw;
        height: 15vw;
        font-size: 2.9vw;
        color: #fff;
        text-align: center;
        margin-top: 6px;
        display: inline-block;
        float: right;
    }
    .grab-make-money .grab-make-right div {
        color: #fff;
        height: 37%;
        overflow: hidden;
    }
}

.grab-luck-tit {
    overflow: hidden;
    width: 50vw;
    height: 5vw;
    font-size: 2vw;
    margin: 25vw auto 0 !important;
    line-height: 22px;
    color: #fbe3b8;
    text-align: center;
}

.grab-luck-draw {
    width: calc(100% - 30px);
    overflow: hidden;
    height: 88px;
    margin: 33px auto 0;
    .luck-draw-list {
        display: flex;
        justify-content: space-evenly;
        .li {
            height: 93px;
            text-align: center;
        }
    }
}

.orderGrabbingMain {
    position: relative;
    background: #fff;
    border-radius: 8px;
}

.imgLabelBox {
    position: absolute;
    top: 25px;
    left: 6px;
    z-index: 10;
    padding: 0.05rem 0.28rem;
    height: 0.38rem;
    background: #fff8e9;
    border-radius: 0.24rem;
    font-size: 16px;
    font-weight: 700;
    color: #ffa900;
    line-height: 0.28rem;
}

.van-image {
    position: relative;
    display: inline-block;
}

.font-microsoft {
    color: #005652;
    font-family: Microsoft YaHei;
    text-align: center;
}

.van-progress {
    margin: 20px 0;
    background: linear-gradient(304deg, rgb(55, 182, 115) 0%, rgb(96, 221, 117) 100%);
    height: 4px;
    border-radius: 5px;
    transition: all 0.5s linear;
}

.history-progress {
    display: -webkit-box;
    white-space: nowrap;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.title {
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
}
