.header-home {
    background-image: url('https://i.imgur.com/wARVaOf.png');
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    width: 100%;
    height: 143px;
    background-repeat: no-repeat;
    background-size: cover;
    .header-style-info {
        width: 100%;
        text-align: center;
    }

    .header-style-info .h1 {
        color: #fcff00;
        font-size: 28px;
        line-height: 50px;
    }

    .header-style-info .h2 {
        color: #ffffff;
        font-size: 22px;
        line-height: 35px;
    }
}

.title-style {
    height: 33px;
    line-height: 33px;
    margin: 16px 0;
    color: #474747;
    font-size: 22px;
    font-weight: 700;
}
