.App {
    min-height: 100vh;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(255, 255, 255);
}

.container-fluid {
    width: 100%;
    display: flex;
    max-width: 360px;
    flex-direction: column;
}

.container {
    width: 360px;
    display: flex;
}

.content {
    flex: 1;
    width: 360px;
    padding-bottom: 70px;
    min-height: 115vh;
}
