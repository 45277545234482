.dorder {
    position: relative;
    margin-bottom: 16px;
    padding-top: 4px;
    background-color: #fe3270;
    background-image: -webkit-linear-gradient(45deg, #fe3270, #fe2c82);
    background-image: linear-gradient(45deg, #fe3270, #fe2c82);
    -webkit-box-shadow: 0 1px 1px 0 rgb(15 32 71 / 55%);
    box-shadow: 0 1px 1px 0 rgb(15 32 71 / 55%);
    -webkit-border-radius: 5px;
    border-radius: 5px;
}
.vip-list {
    width: 95.65%;
    margin: 0 auto;
    background-color: #fff;
    -webkit-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
}
.vip-list .h2 {
    width: 110px;
    height: 22px;
    margin: 0 auto;
    background: url('https://i.imgur.com/tLINzzr.png') no-repeat;
    background-size: 100% 100%;
    line-height: 22px;
    color: #fff;
    font-size: 15px;
    text-align: center;
    font-weight: 700;
}
.vip-list .vip-list-icon {
    width: 100%;
}
.vip-list .vip-list-icon .vip-pic {
    display: block;
    margin: 11px auto;
    width: 100%;
    height: auto;
}

.vip-data {
    width: 95.65%;
    margin: 0 auto;
    background-image: -webkit-linear-gradient(45deg, #fe3270, #fe2c82);
    background-image: linear-gradient(45deg, #fe3270, #fe2c82);
    -webkit-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
}

.vip-data .text {
    overflow: hidden;
    display: block;
    width: 42.48%;
    margin-left: 57.52%;
    font-size: 13px;
    text-align: center;
    color: #fff;
    height: 24px;
    line-height: 24px;
}
.vip-commission {
    position: absolute;
    left: -1px;
    bottom: -2px;
    width: 95px;
    height: 49px;
    padding-left: 5px;
    background: url('https://i.imgur.com/v2WB7Vo.png') no-repeat;
    background-size: 100% 100%;
    color: #fff;
    .text {
        font-size: 13px;
        height: 22px;
        line-height: 22px;
    }
    .number {
        height: 26px;
        line-height: 26px;
        font-weight: 700;
        font-size: 17px;
    }
}

.lock {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
}
