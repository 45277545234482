.system {
    background-color: #0c131d;
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    color: white;
    font-weight: 700;
    font-size: 16px;
    margin-top: 10px;
}

.sub-title {
    color: white;
    background-color: #334357;
    font-weight: 700;
    font-size: 14px;
    margin-top: 10px;
    width: 100%;
    text-align: center;
    line-height: 28px;
}

.banner {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
}
.banner-item {
    width: 150px;
    height: 220px;
    background-color: #334357;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.banner-inner {
    height: 154px;
    background-color: #1e212c;
    border-radius: 10px;
}

.banner-inner-logo {
    display: block;
    position: relative;
}

.banner-logo {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    top: -50%;
    left: 50%;
    transform: translateX(50%) translateY(-50%);
    object-fit: cover;
}

.banner-content {
}

.banner-title {
    font-size: 14px;
    text-align: center;
    color: white;
    margin-top: -24px;
    padding: 0 20%;
}
.banner-company {
    margin-top: 10px;
    color: white;
    font-weight: 700;
    text-align: center;
}

.infomation {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.infomation-item {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 165px;
    max-width: 165px;
    background-color: #1e212c;
    border-radius: 8px;
    padding: 20px 10px;
    color: white;
    font-size: 14px;
    margin-top: 12px;
}

.support {
    width: 100%;
    background-color: #0c131d;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.support-item {
    margin-top: 20px;
    padding-left: 20px;
    width: 280px;
}
.support-header {
    display: flex;
    align-items: center;
}
.support-icon {
    background-color: #1890ff;
    min-width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
}
.support-svg {
    color: white;
}
.support-title {
    color: white;
    font-size: 18px;
    margin-left: 16px;
}
.support-content {
    margin-top: 12px;
    color: white;
    text-align: justify;
    font-size: 14px;
}
