.navbar__footer {
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar__footer--container {
    width: 360px;
}
